@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
    [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea
   {
    @apply bg-gray-400 w-3/6 dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 focus:ring-indigo-500 
    focus:border-indigo-500 block sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}
 /* Removed rounded-full,w-full,bg-white class*/
 /* Removed select element*/


/* .gradient-border {
  border: 2px solid;
  border-image-source: linear-gradient(313.8deg, #0549A4 6.12%, #5DE1E6 95.53%);
  border-image-slice: 1;
  border-radius: 9999px !important;
} */


/* GENERAL STYLES */
.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px; 
  padding:2px; 
  background:linear-gradient(313.8deg,#0549A4 6.12%,#5DE1E6 95.53%); 
  mask: 
    linear-gradient(#000 0 0) content-box, 
    linear-gradient(#000 0 0);
  mask-composite: exclude; 
}

.custom-focus:focus-within {
  outline: 2px solid transparent;
  border: 2px solid #022a60;
  padding: 2.5px;
}

.hover-btn:hover{
  background-color:#0c3f82 !important;
}
.focus-ring {
  @apply focus:border-blue-950 focus:ring focus:ring-blue-200 focus:ring-opacity-50;
}

.overlay-container{
  position: relative;
  width: 100%;
  overflow: hidden; /* Ensures child elements don’t overflow */
  cursor: pointer;
}
.overlay-Image {
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10
}

.overlay-container:hover .overlay-Image {
  opacity: 0.3;
}

.overlay-container:hover .middle {
  opacity: 1;
}

.custom-border-active{
  border-color: #022a60 !important;
}

.nc-AccountPage-profile {
  font-size: 60px;
  font-weight: 400;
}